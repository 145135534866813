const buttons = {
  icon: {
    padding: 0,
    height: `iconButton`,
    width: `iconButton`,
    cursor: `pointer`,
  },
}

export default buttons
