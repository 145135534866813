const messages = {
  primary: {
    color: `text`,
    backgroundColor: `secondary`,
    marginBottom: 4,
    borderLeft: 0,
  },
}

export default messages
