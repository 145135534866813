const CODE_BLOCK_COPY_CLICK_TIMEOUT = 3000
const CODE_BLOCK_CLASS_NAME = `code-block`
const CODE_BLOCK_CONTAINER_CLASS_NAME = `code-block-container`
const CODE_BLOCK_SCROLL_STEP = 100

const SCROLL_PROVIDER_THROTTLE_DELAY = 200

const REGISTERED_HIT_ENDPOINT = `/.netlify/functions/register-hit`

const IMAGES_PATH = `./src/assets/images`
const POSTS_PATH = `./content/posts`
const POST_TEMPLATE_PATH = `./src/templates/post/index.js`

const COLOR_MODE_EVENT_NAME = `colormode`
const COLOR_MODE_STORAGE_KEY = `theme-ui-color-mode`

const FAVICON_FRAME_STORAGE_KEY = `favicon-frame`
const FAVICON_CANVAS_SIZE = 64
const FAVICON_UPDATE_DELAY = 1000
const FAVICON_CANVAS_TIME_STEP = 0.25
const FAVICON_CANVAS_FRAME_NUMBER = 4
const FAVICON_LINK_ELEMENT_ID = `favicon`

module.exports = {
  CODE_BLOCK_COPY_CLICK_TIMEOUT,
  CODE_BLOCK_CLASS_NAME,
  CODE_BLOCK_CONTAINER_CLASS_NAME,
  CODE_BLOCK_SCROLL_STEP,
  SCROLL_PROVIDER_THROTTLE_DELAY,
  REGISTERED_HIT_ENDPOINT,
  IMAGES_PATH,
  POSTS_PATH,
  POST_TEMPLATE_PATH,
  COLOR_MODE_EVENT_NAME,
  COLOR_MODE_STORAGE_KEY,
  FAVICON_CANVAS_SIZE,
  FAVICON_CANVAS_TIME_STEP,
  FAVICON_CANVAS_FRAME_NUMBER,
  FAVICON_FRAME_STORAGE_KEY,
  FAVICON_UPDATE_DELAY,
  FAVICON_LINK_ELEMENT_ID,
}
